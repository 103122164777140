import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @format */

import React from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { Container } from "../_global";
import { McoHeaderWrapper, McoWrapper, Subtitle } from "../Home/style";
import { Loading } from "../Category/Offers/style";
import { getHowItWorks } from "../Home/homeUtils";
import { OFFER_TYPES } from "../../constants/app";
import Link from 'next/link';
import { RightArrow } from "../InfoBox/style";
import { graphqlGetMcoOffers, graphqlMcoActivate } from "../../data/queries";
import { offerProps, offerPropsDefault } from "../CardOffers/mcoOfferProps";
import NurseryOffer from "../NurseryOffer";
import moment from 'moment';
import { getFilteredOffers } from "../CardOffers/mcoUtils";
import OffersCarousel from "../OffersCarousel";
import { getVisaAmexEnrolmentFlag } from "../../lib/optimizelyFlagUtils";
var CardOffers = function CardOffers(_ref) {
  var enrolled = _ref.enrolled,
    loading = _ref.loading,
    mcoOffers = _ref.mcoOffers,
    mcoActivate = _ref.mcoActivate,
    ffn = _ref.ffn,
    memberStatus = _ref.memberStatus;
  var activeMcoOffers = mcoOffers && mcoOffers.filter(function (o) {
    return moment(o.eventEndDate).isSameOrAfter(moment(), 'day');
  });
  var filteredMco = getFilteredOffers(activeMcoOffers, {
    sortOrder: 'start-date'
  }).slice(0, 10);
  var isVisaAmexEnabled = getVisaAmexEnrolmentFlag();
  return __jsx(React.Fragment, null, mcoOffers && enrolled && memberStatus !== 'OPTED_OUT' && __jsx(McoWrapper, null, __jsx(Container, {
    wideView: true
  }, __jsx(McoHeaderWrapper, null, __jsx(Subtitle, null, "Card Offers"), mcoOffers.length > 10 && __jsx(Link, {
    href: "/card-offers"
  }, __jsx("a", null, "View all offers ", __jsx(RightArrow, null)))), getHowItWorks('MCO')), loading ? __jsx(Loading, null) : __jsx(OffersCarousel, {
    offers: filteredMco,
    mcoActivate: mcoActivate,
    ffn: ffn,
    offerType: OFFER_TYPES.MCO,
    isVisaAmexEnabled: isVisaAmexEnabled
  })), !enrolled && __jsx(NurseryOffer, null));
};
CardOffers.defaultProps = _objectSpread(_objectSpread({}, offerPropsDefault), {}, {
  ffn: ''
});
var mapStateToProps = function mapStateToProps(_ref2) {
  var user = _ref2.user;
  return {
    authorized: user.authorized,
    memberStatus: user.programStatus,
    authenticated: user.authenticated,
    enrolled: user.enrolled,
    ffn: user.ffn,
    offerCount: user.offerCount
  };
};
export default compose(connect(mapStateToProps), graphqlGetMcoOffers(), graphqlMcoActivate())(CardOffers);