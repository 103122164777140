import _slicedToArray from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import { useDecision } from '@optimizely/react-sdk';
import { Modal, Button, Icon } from "../../_global";
import { MARKETING_EMAIL_CODE, MARKETING_EMAIL_COOKIE_NAME } from "../../../constants/app";
import { colours, fontSizes } from "../../../constants/css";
import { updateMemberPreferences, closeOptInModal } from "../../../reducers/user";
import { ErrorIcon } from "../LinkCard/style";
import { getDaysFromTodayDate } from "../../../utils/dateUtils";
import { OPTIMIZELY_FLAGS } from "../../../lib/optimizelyClient";
var cookies = new Cookies();
var Container = styled.div.withConfig({
  displayName: "MarketingOptIn__Container",
  componentId: "sc-bwiz1f-0"
})(["margin:3rem 0 2rem;padding:0 2rem;text-align:center;"]);
var ConfirmationContainer = styled.div.withConfig({
  displayName: "MarketingOptIn__ConfirmationContainer",
  componentId: "sc-bwiz1f-1"
})(["display:flex;margin-bottom:1.5rem;align-items:center;flex-direction:column;"]);
var ConfirmationTitle = styled.h2.withConfig({
  displayName: "MarketingOptIn__ConfirmationTitle",
  componentId: "sc-bwiz1f-2"
})(["font-size:1.375rem;text-align:left;margin:0;"]);
var ConfirmationTick = styled.div.withConfig({
  displayName: "MarketingOptIn__ConfirmationTick",
  componentId: "sc-bwiz1f-3"
})(["display:flex;align-items:center;justify-content:center;width:50px;height:50px;border-radius:50%;background:", ";flex-shrink:0;"], colours.qantasLightCyan);
var CallToAction = styled(Button).withConfig({
  displayName: "MarketingOptIn__CallToAction",
  componentId: "sc-bwiz1f-4"
})(["margin:1rem auto 0;max-width:250px;display:block;", ""], function (props) {
  return props.link && "\n    margin: 0 auto;\n    text-transform: none;\n    text-decoration: underline;\n  ";
});
var ErrorContainer = styled.div.withConfig({
  displayName: "MarketingOptIn__ErrorContainer",
  componentId: "sc-bwiz1f-5"
})(["display:flex;align-items:center;margin-bottom:0.75rem;h2{font-size:", ";margin:0 0 0 0.75rem;}"], fontSizes.xl);
var MarketingOptIn = function MarketingOptIn(props) {
  var _props$showOpt = props.showOpt,
    showOpt = _props$showOpt === void 0 ? true : _props$showOpt;
  var _useState = useState({
      isDismissed: false,
      loading: false,
      error: false,
      success: false
    }),
    states = _useState[0],
    setStates = _useState[1];
  var updateStates = function updateStates(newStates) {
    setStates(function (prevStates) {
      return _objectSpread(_objectSpread({}, prevStates), newStates);
    });
  };
  var _useDecision = useDecision(OPTIMIZELY_FLAGS.SHO_EMAIL_PREFERENCE_CUTOVER),
    _useDecision2 = _slicedToArray(_useDecision, 1),
    shoEmailPreferenceEnabled = _useDecision2[0].enabled;
  var dismissModal = function dismissModal() {
    updateStates({
      isDismissed: true
    });
    props.closeOptInModal();
  };
  var handleOptIn = function handleOptIn() {
    updateStates({
      loading: true
    });
    props.updateMemberPreferences(MARKETING_EMAIL_CODE).then(function () {
      updateStates({
        loading: false,
        success: true
      });
    }).catch(function () {
      updateStates({
        loading: false,
        error: true
      });
    });
  };
  var handleOptOUt = function handleOptOUt() {
    updateStates({
      isDismissed: true
    });
    cookies.set(MARKETING_EMAIL_COOKIE_NAME, true, {
      expires: getDaysFromTodayDate(90),
      path: '/'
    });
    props.closeOptInModal();
  };
  var ctaCopy = states.loading ? 'opting in' : 'Yes, opt in';
  return __jsx(Modal, {
    show: !states.isDismissed && showOpt,
    onClose: handleOptOUt,
    width: 440
  }, __jsx(Container, null, states.success ? __jsx("div", null, __jsx(ConfirmationContainer, null, __jsx(ConfirmationTick, null, __jsx(Icon, {
    iconType: "tick"
  })), __jsx(ConfirmationTitle, null, "Success")), __jsx("p", null, "You can now look forward to bonus Qantas Points offers delivered to your inbox."), __jsx(CallToAction, {
    primary: true,
    block: true,
    onPress: dismissModal
  }, "Ok, got it")) : __jsx("div", null, !states.error ? __jsx("div", null, __jsx("h2", null, "It looks like you're not receiving shopping offers in your inbox"), shoEmailPreferenceEnabled ? __jsx("p", null, "To receive bonus offers via email, opt in to receive offers from our everyday and retail partners.", __jsx("br", null), "Would you like to opt in now?") : __jsx("p", null, "To receive bonus offers via email, opt in to receive exclusive offers from our program partners. Would you like to opt in now?")) : __jsx(ErrorContainer, null, __jsx(ErrorIcon, null), __jsx("h2", null, "Something went wrong")), __jsx("div", null, __jsx(CallToAction, {
    primary: true,
    block: true,
    loading: states.loading,
    onPress: handleOptIn
  }, !states.error ? ctaCopy : 'Try again'), !states.error && __jsx(CallToAction, {
    link: true,
    onPress: handleOptOUt
  }, "No thanks")))));
};
var mapStateToProps = function mapStateToProps(_ref) {
  var showOptIn = _ref.user.showOptIn;
  return {
    showOptIn: showOptIn
  };
};
var mapDispatchToProps = {
  updateMemberPreferences: updateMemberPreferences,
  closeOptInModal: closeOptInModal
};
export default connect(mapStateToProps, mapDispatchToProps)(MarketingOptIn);