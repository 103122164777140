import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _FEATURE_TOGGLE_DEFAU;
/** @format */

var _ = require('lodash');
export var FEATURE_TOGGLE = {
  WOOLWORTHS_DOWN_FOR_MAINTENANCE: 'merchant-woolworths-maintenance-on',
  WOOLWORTHS_DOWN_FOR_MAINTENANCE_LINK: 'merchant-woolworths-maintenance-cta-link',
  TERRYWHITE_DOWN_FOR_MAINTENANCE: 'merchant-terrywhite-maintenance-on',
  TERRYWHITE_DOWN_FOR_MAINTENANCE_LINK: 'merchant-terrywhite-maintenance-cta-link',
  MASTERCARD_ENROLMENT_OUTAGE: 'outage-link-cards-page',
  VISA_AMEX_ENROLMENT_OUTAGE: 'outage-link-cards-page-lnow',
  VISA_ENROLMENT: 'enrolment.visa.enabled',
  // not in use anymore
  VISA_AMEX_ENROLMENT: 'enrolment.visa-amex.enabled',
  // not in use anymore
  CARD_LINK: 'qs-card-link',
  BONUS_POINTS_MSG: 'bonus-points-message',
  ANONYMOUS: 'isAnonymous',
  UNLINK_CARD_DISABLED: 'unlink-card-disable',
  OPTOUT_API_ENABLED: 'optout-api-enable',
  EVERYDAY_REWARDS_ONE_CLICK_PROMPT: 'everyday-rewards-one-click-prompt',
  EVERYDAY_REWARDS_COMPETITION_BANNER: 'edr-competition-banner',
  EDR_IN_PAGE_IFRAME_ENABLED: 'edr-in-page-iframe-enabled',
  EDR_NZ_IP_PROMPT_ENABLED: 'edr-nz-ip-prompt-enabled'
};
var FEATURE_TOGGLE_DEFAULTS = (_FEATURE_TOGGLE_DEFAU = {}, _defineProperty(_FEATURE_TOGGLE_DEFAU, FEATURE_TOGGLE.WOOLWORTHS_DOWN_FOR_MAINTENANCE, false), _defineProperty(_FEATURE_TOGGLE_DEFAU, FEATURE_TOGGLE.WOOLWORTHS_DOWN_FOR_MAINTENANCE_LINK, 'https://www.woolworthsrewards.com.au/login.html'), _defineProperty(_FEATURE_TOGGLE_DEFAU, FEATURE_TOGGLE.TERRYWHITE_DOWN_FOR_MAINTENANCE, false), _defineProperty(_FEATURE_TOGGLE_DEFAU, FEATURE_TOGGLE.TERRYWHITE_DOWN_FOR_MAINTENANCE_LINK, 'https://www.terrywhitechemmart.com.au/qantas/'), _defineProperty(_FEATURE_TOGGLE_DEFAU, FEATURE_TOGGLE.INCENTIVE_NETWORK_OUTAGE, false), _defineProperty(_FEATURE_TOGGLE_DEFAU, FEATURE_TOGGLE.EDR_NZ_IP_PROMPT_ENABLED, false), _FEATURE_TOGGLE_DEFAU);
export var getFeatureFlag = function getFeatureFlag(_ref) {
  var _ref$key = _ref.key,
    key = _ref$key === void 0 ? '' : _ref$key,
    _ref$flags = _ref.flags,
    flags = _ref$flags === void 0 ? {} : _ref$flags,
    _ref$anonymousLDFlags = _ref.anonymousLDFlags,
    anonymousLDFlags = _ref$anonymousLDFlags === void 0 ? {} : _ref$anonymousLDFlags;
  var FLAG_STORE = !_.isNil(flags) && !_.isEmpty(flags) ? flags : anonymousLDFlags;
  var defaultValue = FEATURE_TOGGLE_DEFAULTS[key];
  var value = FLAG_STORE[key];
  if (_.isNil(value) || value === '""' || value === 'null') {
    // LD Variation keys ALWAYS have to contain some kind of value
    return defaultValue;
  }
  return value;
};