var __jsx = React.createElement;
import React from 'react';
import { slugify } from "../../utils/slugify";
import { compose } from 'react-apollo';
import Link from 'next/link';
import Image from 'next/image';
import { connect } from 'react-redux';
import config from "../../utils/clientConfig";
import { graphqlNurseryOffer } from "../../data/queries";
import { FEATURE_TOGGLE } from "../../utils/getLDFlag";
var merchantsEnticer = "/static/home/mco-merchants-enticer-new.jpg";
import NurseryCards from "./NurseryCards";
import { LinkCard, LinkCardBox, LinkWrapper, LinkTitle, MerchantsLogo, LinkCopyContainer, HowItWorksLink, LinkSubTitle, LinkCopy, LinkButton, Pebble, DisclaimerCopy } from "./style";
import { getVisaAmexEnrolmentFlag } from "../../lib/optimizelyFlagUtils";
var getLabels = function getLabels() {
  var isVisaAmexEnabled = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return isVisaAmexEnabled ? {
    cardTypes: 'Australian Mastercard®, Visa® or American Express card',
    linkButton: 'Link card',
    disclaimer: 'Got a Mastercard, Visa or American Express card?'
  } : {
    cardTypes: 'Australian Mastercard® or Visa® card',
    linkButton: 'Link card',
    disclaimer: 'Got a Mastercard or Visa card?'
  };
};
var NurseryOffer = function NurseryOffer(_ref) {
  var nurseryOffer = _ref.nurseryOffer,
    flags = _ref.flags,
    howItWorksRef = _ref.howItWorksRef;
  var offers = nurseryOffer.offers;
  var offerCount = offers && offers.length || 0;
  var isSingleOffer = offerCount === 1;
  var isVisaAmexEnabled = getVisaAmexEnrolmentFlag() || false;
  var _getLabels = getLabels(isVisaAmexEnabled),
    cardTypes = _getLabels.cardTypes,
    linkButton = _getLabels.linkButton,
    disclaimer = _getLabels.disclaimer;
  return __jsx(LinkCard, null, __jsx(LinkWrapper, {
    isSingleOffer: isSingleOffer
  }, __jsx(LinkCopy, null, __jsx(LinkCopyContainer, null, !howItWorksRef && __jsx(LinkTitle, null, "Discover Card Offers"), __jsx(LinkCardBox, null, flags[FEATURE_TOGGLE.BONUS_POINTS_MSG] && __jsx(Pebble, null), __jsx(LinkSubTitle, null, "Link your ", cardTypes, "* to unlock exclusive points offers at over 100 brands"), __jsx(MerchantsLogo, null, __jsx(Image, {
    src: merchantsEnticer,
    alt: "Some of our brands",
    layout: "fill",
    objectFit: "contain"
  })), flags[FEATURE_TOGGLE.CARD_LINK] ? __jsx(Link, {
    href: "/link-card"
  }, __jsx("a", null, __jsx(LinkButton, {
    primary: true,
    "data-attribute": slugify(linkButton)
  }, linkButton))) : __jsx("a", {
    target: "_blank",
    rel: "noopener noreferrer",
    href: "".concat(config.MCO_SITE_ACCOUNT)
  }, __jsx(LinkButton, {
    primary: true,
    "data-attribute": slugify(linkButton)
  }, linkButton)), howItWorksRef ? __jsx(HowItWorksLink, null, __jsx("a", {
    role: "link",
    onClick: function onClick() {
      return howItWorksRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    }
  }, "How it works")) : __jsx(HowItWorksLink, null, __jsx(Link, {
    href: "/how-it-works",
    as: "/how-it-works#card-offers"
  }, __jsx("a", null, "How it works")))), __jsx(DisclaimerCopy, null, "*", "".concat(disclaimer, " "), "Find out how you can earn Qantas Points", ' ', __jsx(Link, {
    href: "/faqs",
    as: "/faqs?id=25"
  }, __jsx("a", null, "here")), "."))), offerCount > 0 && __jsx(NurseryCards, {
    offers: offers,
    cardTypeText: cardTypes
  })));
};
NurseryOffer.defaultProps = {
  nurseryOffer: {
    offers: [],
    loading: true
  },
  flags: {},
  howItWorksRef: null
};
var mapStateToProps = function mapStateToProps(_ref2) {
  var flags = _ref2.flags;
  return {
    flags: flags
  };
};
export default compose(connect(mapStateToProps), graphqlNurseryOffer())(NurseryOffer);