import _defineProperty from "/home/node/app/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _FEATURE_FLAG_DEFAULT;
/** @format */

import { createInstance } from '@optimizely/react-sdk';
import config from "../utils/clientConfig";
export var OPTIMIZELY_FLAGS = {
  SHO_OPT_TEST: 'sho-opt-test',
  SHO_EMAIL_PREFERENCE_CUTOVER: 'sho-email-preference-cutover',
  INCENTIVE_NETWORK_OUTAGE: 'sho-incentive-network-outage-enabled',
  MCO_VISA_AND_AMEX_ENABLED: 'mco-visa-and-amex-enabled',
  // New Woolworths maintenance flags
  WOOLWORTHS_MAINTENANCE: 'edr-maintenance-on',
  WOOLWORTHS_MAINTENANCE_LINK: 'edr-maintenance-cta-link',
  SHO_SITE_WIDE_OUTAGE: 'sho-site-wide-outage',
  MCO_LINK_CARDS_OUTAGE_MASTERCARD: 'mco-link-cards-outage-mastercard',
  MCO_LINK_CARDS_OUTAGE_VISA: 'mco-link-cards-outage-visa'
};
var optimizelyClient = createInstance({
  sdkKey: config.OPTIMIZELY_SDK_KEY,
  logLevel: 'error'
});

// Default values for feature flags
export var FEATURE_FLAG_DEFAULTS = (_FEATURE_FLAG_DEFAULT = {}, _defineProperty(_FEATURE_FLAG_DEFAULT, OPTIMIZELY_FLAGS.WOOLWORTHS_MAINTENANCE, false), _defineProperty(_FEATURE_FLAG_DEFAULT, OPTIMIZELY_FLAGS.WOOLWORTHS_MAINTENANCE_LINK, 'https://www.woolworthsrewards.com.au/login.html'), _FEATURE_FLAG_DEFAULT);
export var isClientValid = function isClientValid() {
  return optimizelyClient.getOptimizelyConfig() !== null;
};
export default optimizelyClient;